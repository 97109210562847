<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="10" md="10">
        <ecommerce-statistics :data="data.statisticsProduit" title="Produits" />
      </b-col>
      <b-col xl="2" md="2">
        <ecommerce-statistics :data="data.statisticsCommand" title="Commande" />
      </b-col>
      <b-col xl="12" md="12">
        <ecommerce-statistics :data="data.statisticsBoost" title="Boost" />
      </b-col>
      <b-col xl="8" md="8">
        <ecommerce-statistics
          :data="data.statisticsUsers"
          title="Utilisateur"
        />
      </b-col>
      <b-col xl="4" md="4">
        <ecommerce-statistics
          :data="data.statisticsContact"
          title="Contact & abonnés"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import axios from "axios";
import EcommerceStatistics from "./EcommerceStatistics.vue";

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
  },
  data() {
    return {
      data: {
        statisticsProduit:[
          {
            icon: "ShoppingCartIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Produit",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "ShoppingCartIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Catégorie",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "ShoppingCartIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Sous catégorie",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "ShoppingCartIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Tags",
            customClass: "mb-2 mb-sm-0",
          },
        ],
          statisticsCommand: [
          {
            icon: "FileTextIcon",
            color: "light-primary",
            title: "0",
            subtitle: "All",
            customClass: "mb-2 mb-xl-0",
          },
        ],
        statisticsUsers: [
          {
            icon: "UserCheckIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "UserPlusIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Professionels",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "UserXIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Demande de pro",
            customClass: "",
          },
        ],
        statisticsContact: [
          {
            icon: "MessageSquareIcon",
            color: "light-primary",
            title: "0",
            subtitle: "All",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "MessageSquareIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-sm-0",
          },
        ],
          statisticsBoost: [
          {
            icon: "DollarSignIcon",
            color: "light-primary",
            title: "0",
            subtitle: "All",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "DollarSignIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-sm-0",
          },
             {
            icon: "DollarSignIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-sm-0",
          },
             {
            icon: "DollarSignIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-sm-0",
          },
             {
            icon: "DollarSignIcon",
            color: "light-primary",
            title: "0",
            subtitle: "Clients",
            customClass: "mb-2 mb-sm-0",
          },
        ],
      },
    }
  },
  async created() {
    setTimeout(this.getData(), 500);
  },
  methods: {
    async getData() {
      const result = await axios.get(
        "http://102.219.178.151/backend/api/dashboard"
      );
      const { data } = result;
      console.log("test", data);
      /*   this.data.statisticsUsers[0].title = data.prospects;
      this.data.statisticsUsers[1].title = data.customers;
      this.data.statisticsUsers[2].title = data.suppliers;
      this.data.statisticsQuotation[0].title = data.products;
      this.data.statisticsQuotation[1].title = data.categories;
      this.data.statisticsQuotation[2].title = data.subCategories;
      this.data.statisticsInstantQuotation[0].title = data.salesQuotations;
      this.data.statisticsInstantQuotation[1].title = data.salesCommands;
      this.data.statisticsInstantQuotation[2].title = data.salesBills;
      this.data.statisticsBooking[0].title = data.purchaseQuotations;
      this.data.statisticsBooking[1].title = data.purchaseCommands;
      this.data.statisticsBooking[2].title = data.purchaseBills;
      } */
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
